import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import FeaturedProducts from '../components/FeaturedProducts';
import ModularBlocks from '../components/ModularBlocks';
import BlogListing from '../components/BlogListing';

const HomePageTemplate = ({
  data: {
    datoCmsHome: {
      seoMetaTags,
      title,
      bannerImage,
      bannerHeading,
      bannerLink,
      bannerDarkOverlayOpacity,
      featuredProductsHeading,
      featuredProducts: featuredProductsDato,
      modularBlocks,
    },
    allShopifyProduct: { nodes: featuredProductsShopify },
  },
}) => {
  const featuredProducts = featuredProductsDato.map(({ shopifyProduct }) =>
    featuredProductsShopify.find(({ handle }) => handle === shopifyProduct)
  );

  return (
    <Layout seo={seoMetaTags}>
      <main>
        <Banner
          heading={bannerHeading.replace(/<p>(.*)<\/p>/g, '$1') || title}
          image={bannerImage}
          link={bannerLink}
          darkOverlayOpacity={bannerDarkOverlayOpacity}
          isHomepage={true}
        />
        <FeaturedProducts
          heading={featuredProductsHeading}
          items={featuredProducts}
        />
        <ModularBlocks items={modularBlocks} />
        <BlogListing />
      </main>
    </Layout>
  );
};

export const HomePageTemplateQuery = graphql`
  query HomePageTemplateQuery($featuredProducts: [String]!) {
    datoCmsHome {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      bannerImage {
        ...HomePageBannerImageFragment
      }
      bannerHeading
      bannerLink {
        text
        page {
          ...LinkFragment
        }
      }
      bannerDarkOverlayOpacity
      featuredProductsHeading
      featuredProducts {
        shopifyProduct
      }
      modularBlocks {
        ...ContentBlocksImageLinksModularBlockFragment
        ...FeaturedProductCategoriesModularBlockFragment
        ...FeaturedRecipesModularBlockFragment
        ...StatementTextModularBlockFragment
      }
    }
    allShopifyProduct(filter: { handle: { in: $featuredProducts } }) {
      nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default HomePageTemplate;
