import React from 'react';
import styled from 'styled-components';
import { breakpointSizes, sectionMargins } from '../styles';
import ProductsSlider from './ProductsSlider';
import ProductCard from './ProductCard';

const StyledFeaturedProducts = styled.section`
  ${sectionMargins()};
`;

const FeaturedProducts = ({ heading, items }) => {
  const sliderOptions = {
    spaceBetween: 20,
    slidesPerView: 1,
    breakpoints: {
      [breakpointSizes.tiny]: {
        slidesPerView: 2,
      },
      [breakpointSizes.smedium]: {
        slidesPerView: 3,
      },
    },
  };

  return (
    items.length > 0 && (
      <StyledFeaturedProducts>
        <ProductsSlider heading={heading} sliderOptions={sliderOptions}>
          {items.map(item => (
            <ProductCard
              key={item.id}
              title={item.title}
              handle={item.handle}
              featuredImage={item.featuredImage}
              priceRangeV2={item.priceRangeV2}
              hasOnlyDefaultVariant={item.hasOnlyDefaultVariant}
              totalInventory={item.totalInventory}
              variants={item.variants}
            />
          ))}
        </ProductsSlider>
      </StyledFeaturedProducts>
    )
  );
};

export default FeaturedProducts;
